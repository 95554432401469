import React, {useEffect, useState} from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import Img from 'gatsby-image';
import {Column, Columns} from 'bloomer';
import {ContObraComp} from '../../ContObra';
import {GlobalStylePage, ContArrow, ContImgMoon, ImgMoon, ContImgCloud, ImgCloud} from './styles';
import {ArrowNavTop, ArrowNavRight, ArrowNavBottom, ArrowNavLeft} from '../../ArrowsNavigation';
import {ContImgLeft, ContImgRight, GlobalStyle} from '../../../styles/GlobalStyles';
import {gsap} from "gsap";
import useSound from 'use-sound';
import {SpeechBubbleMini} from '../../SpeechBubbles/SpeechBubbleMini';
import {SpeechBubble} from '../../SpeechBubbles';
import imgMoon from '../../../images/obras/obra-10/luna.png';
import imgCloud from '../../../images/obras/obra-10/nube.png';
import imgLeft from '../../../images/obras/obra-9/obra.jpeg';
import imgRight from '../../../images/obras/obra-11/obra.png';
import soundMoon from '../../../images/obras/obra-1/moon.mp3';
import {BtnReplay} from '../../BtnReplay';
import {BtnParrilla} from '../../BtnParrilla';

const arNav = {
  top: null,
  right: 11,
  bottom: null,
  left: 9,
};

const Page10Comp = () => {
  const [showArrows, setShowArrows] = useState(false);
  const [initSpeechBubbleMini, setInitSpeechBubbleMini] = useState(false);
  const [initSpeechBubble, setInitSpeechBubble] = useState(false);
  const [initAnimatedMoon, setInitAnimatedMoon] = useState(false);
  const [initAnimatedCloud, setInitAnimatedCloud] = useState(false);
  const [initSoundMoon, setInitSoundMoon] = useState(false);
  const [reLaunchAll, setReLaunchAll] = useState(false);

  /*const data = useStaticQuery(graphql`
    {
      file(relativePath: {eq: "obras/obra-1/obra.jpeg"}) {
        childImageSharp {
          fixed(width: 340, height: 670) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);*/
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "obras/obra-10/obra.png" }) {
        childImageSharp {
          fluid(maxWidth: 750, maxHeight: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const mouseOver = () => {
    setShowArrows(true);
  };
  const mouseLeaver = () => {
    setShowArrows(false);
  };

  const fnReLaunchAll = () => {
    fnReLaunchMoon(0);
    fnReLaunchCloud(0);
    setReLaunchAll(!reLaunchAll);
  }

  const fnReLaunchMoon = (noReplay = 1) => {
    setInitAnimatedMoon(false);
    setInitSoundMoon(false);

    if(noReplay) {
      setTimeout(function () {
        setInitAnimatedMoon(true);
      }, 1000);
    }
  };
  const fnReLaunchCloud = (noReplay = 1) => {
    setInitAnimatedCloud(false);
    // setInitSoundCloud(false);

    if(noReplay) {
      setTimeout(function () {
        setInitAnimatedCloud(true);
      }, 1000);
    }
  };

  const fnSpeechBubbleMini = () => {
    return (
      <>
        {
          initSpeechBubbleMini &&
          <SpeechBubbleMini
            id={'speech-bubble-mini'}
            className={'speech-bubble-mini'}
            onClick={() => setInitSpeechBubble(true)}
          />
        }
      </>
    )
  }
  const fnSpeechBubble = () => {
    return (
      <>
        {
          initSpeechBubble &&
          <SpeechBubble
            id={'speech-bubble'}
            className={''}
            classClose={'close-top-left'}
            onClick={() => setInitSpeechBubble(false)}
          >
            <div>Ahora el seto se blanquea durante una hora con flor</div>
            <div>transitoria de nieve, una flor más repentina,</div>
            <div>que la del verano, ni brota ni se marchita.</div>
          </SpeechBubble>
        }
      </>
    )
  }

  const fnAnimateMoon = () => {
    return (
      <ContImgMoon className={'img-animated'} onClick={() => fnReLaunchMoon()}>
        {
          initAnimatedMoon &&
          <ImgMoon
            id={'animate-moon'}
            className={'animate-moon'}
            src={imgMoon}
            alt="Luna"
          />
        }
      </ContImgMoon>
    )
  }

  const fnAnimateCloud = () => {
    return (
      <ContImgCloud className={'img-animated'} onClick={() => fnReLaunchCloud()}>
        {
          initAnimatedCloud &&
          <ImgCloud
            id={'animate-cloud'}
            className={'animate-cloud'}
            src={imgCloud}
            alt="Nube"
          />
        }
      </ContImgCloud>
    )
  }

  const fnLeft = () => {
    return <ContImgLeft src={imgLeft} />
  }
  const fnRight = () => {
    return <ContImgRight src={imgRight} />
  }

  const [playMoon, { stopMoon }] = useSound(
    soundMoon,
    { volume: 0.1 }
  );

  useEffect(() => {
    setTimeout(function (){
      setInitAnimatedMoon(true);
    }, 3000);

    setTimeout(function (){
      setInitAnimatedCloud(true);
    }, 6000);

    setTimeout(function (){
      setInitSpeechBubbleMini(true);
    }, 9000);
  },[reLaunchAll]);

  useEffect(() => {
    const windowWrap = gsap.utils.wrap(0, window.innerWidth);
    const winWidth = window.innerWidth;
    let arPosMoon = {};
    let arPosCloud = {};

    if(winWidth < 767){
      arPosMoon = {x: -10}
      arPosCloud = {x: 300}
    } else if(winWidth < 1023){
      arPosMoon = {x: -20}
      arPosCloud = {x: 480}
    } else if(winWidth < 1215){
      arPosMoon = {x: -50}
      arPosCloud = {x: 600}
    } else if(winWidth < 1407){
      arPosMoon = {x: -100}
      arPosCloud = {x: 800}
    } else if(winWidth < 1919){
      arPosMoon = {x: -100}
      arPosCloud = {x: 800}
    } else {
      arPosMoon = {x: -100}
      arPosCloud = {x: 1100}
    }

    if(initAnimatedMoon){
      gsap.to(".animate-moon", {
        x: arPosMoon.x,
        duration: 3,
        delay: 0,
      });
      if(!initSoundMoon){
        playMoon();
        setInitSoundMoon(true);
      }
    }

    if(initAnimatedCloud){
      gsap.to(".animate-cloud", {
        x: arPosCloud.x,
        duration: 4,
        delay: 0,
        opacity: 1,
      });
      gsap.to(".animate-cloud", {
        x: arPosCloud.x,
        opacity: 0,
        duration: 1,
        delay: 3,
      });
      /*if(!initSoundCloud){
        playCloud();
        setInitSoundCloud(true);
      }*/
    }

  },[initAnimatedMoon, initAnimatedCloud]);

  return (
    <ContObraComp>
      <BtnParrilla/>
      <BtnReplay onClick={() => fnReLaunchAll()}/>
      <div className={'columns-padding-no cont-obra-height'} onMouseOver={mouseOver} onMouseLeave={mouseLeaver}>
        <GlobalStyle/>
        <GlobalStylePage/>

        <Columns className="columns-responsive" isMultiline={false} isCentered={true}>
          <Column isSize={{mobile: 12, tablet: 12, desktop: 12, widescreen: 12}} className={'flex-center'}>
            <ContArrow>
              {showArrows && <ArrowNavTop toPage={arNav.top}/>}
            </ContArrow>
          </Column>
        </Columns>

        <Columns className="columns-responsive columns-obra-height flex-center" isMultiline={false} isCentered={true}>

          <Column isSize={{mobile: 1, tablet: 1, desktop: 1, widescreen: 1}} className={'flex-center'}>
            <ContArrow>
              {showArrows && <ArrowNavLeft toPage={arNav.left}/>}
            </ContArrow>
          </Column>

          <Column isSize={{mobile: 10, tablet: 10, desktop: 10, widescreen: 10}} className={'flex-center'}>
            {/*<Img fixed={data.file.childImageSharp.fixed} />*/}
            <Img className={'img-obra'} fluid={data.placeholderImage.childImageSharp.fluid}/>
            {fnAnimateMoon()}
            {fnAnimateCloud()}
            {fnSpeechBubbleMini()}
            {fnSpeechBubble()}
            {fnLeft()}
            {fnRight()}
          </Column>

          <Column isSize={{mobile: 1, tablet: 1, desktop: 1, widescreen: 1}} className={'flex-center'}>
            <ContArrow>
              {showArrows && <ArrowNavRight toPage={arNav.right}/>}
            </ContArrow>
          </Column>

        </Columns>

        <Columns className="columns-responsive" isMultiline={false} isCentered={true}>
          <Column isSize={{mobile: 12, tablet: 12, desktop: 12, widescreen: 12}} className={'flex-center'}>
            <ContArrow>
              {showArrows && <ArrowNavBottom toPage={arNav.bottom}/>}
            </ContArrow>
          </Column>
        </Columns>
      </div>
    </ContObraComp>
  );
};

export default Page10Comp;
