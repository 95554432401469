import styled, {createGlobalStyle} from 'styled-components';

export const GlobalStylePage = createGlobalStyle`
  @media(min-width: 300px) and (max-width: 767px){
    .img-obra{
      width: 100%;
      height: 100%;
    }
    .speech-bubble-mini{
      margin-top: -315px;
      margin-left: 100px;
    }
  }
  @media(min-width: 768px) and (max-width: 1023px){
    .img-obra{
      width: 570px;
      height: 100%;
    }
    .speech-bubble-mini{
      margin-top: -410px;
      margin-left: 265px;
    }
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    .img-obra{
      width: 786px;
      height: 100%;
    }
    .speech-bubble-mini{
      margin-top: 20px;
      margin-left: 170px;
    }
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    .img-obra{
      width: 400px;
      height: 115%;
    }
    .speech-bubble-mini{
      margin-top: 20px;
      margin-left: 170px;
    }
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    .img-obra{
      width: 620px;
      height: 976px;
    }
    .speech-bubble-mini{
      margin-top: 20px;
      margin-left: 270px;
    }
  }
  @media(min-width: 1920px){
    .img-obra{
      width: 720px;
      height: 1150px;
    }
    .speech-bubble-mini{
      margin-top: 20px;
      margin-left: 300px;
    }
  }
`
export const ContArrow = styled.div`
  width: 100%;
  height: 40px;
  z-index: 999;
`
export const ContImgMoon = styled.div`
  position: absolute;
  width: 100%;
  height: auto;
  top: 0;
  left: 0;
  z-index: 99;
  
  @media(min-width: 300px) and (max-width: 767px){
    position: absolute;
    width: 100px;
    height: 100px;
    left: 50%;
    margin-left: 86px;
    margin-top: 90px;
    z-index: 99;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    position: absolute;
    width: 200px;
    height: 200px;
    left: 50%;
    margin-left: 180px;
    margin-top: 120px;
    z-index: 99;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    position: absolute;
    width: 270px;
    height: 270px;
    left: 50%;
    margin-left: 240px;
    margin-top: 120px;
    z-index: 99;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    position: absolute;
    width: 200px;
    height: 200px;
    left: 50%;
    margin-left: 200px;
    margin-top: 30px;
    z-index: 99;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    position: absolute;
    width: 250px;
    height: 250px;
    left: 50%;
    margin-left: 275px;
    margin-top: 30px;
    z-index: 99;
  }
  @media(min-width: 1920px){
    position: absolute;
    width: 300px;
    height: 300px;
    left: 50%;
    margin-left: 300px;
    margin-top: 30px;
    z-index: 99;
  }
`
export const ImgMoon = styled.img`
  width: 100%;
  height: 100%;
`
export const ContImgCloud = styled.div`
  position: absolute;
  width: 100%;
  height: auto;
  top: 50%;
  left: 50%;
  z-index: 99;

  @media(min-width: 300px) and (max-width: 767px){
    width: 280px;
    margin-left: -400px;
    margin-top: -72px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 300px;
    margin-left: -400px;
    margin-top: 170px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    width: 350px;
    margin-left: -460px;
    margin-top: 220px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    width: 320px;
    margin-left: -500px;
    margin-top: 65px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    width: 360px;
    margin-left: -550px;
    margin-top: 162px;
  }
  @media(min-width: 1920px){
    width: 400px;
    margin-left: -600px;
    margin-top: 194px;
  }
`
export const ImgCloud = styled.img`
  width: 100%;
  height: 100%;
`
